export const actionButtonStyle = {
  color: '#323130',
  height: 24,
  display: 'inline-block',
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '14px',
  padding: '4px 23px',
  marginLeft: '15px',
  background: '#FFFFFF',
  border: '1px solid #C8C6C4',
  boxSizing: 'border-box',
  borderRadius: '2px',
  position: 'relative',
  clear: 'both',
  marginTop: '6px',
  flexShrink: 0,
  '&:hover': {
    backgroundColor: 'rgb(237, 235, 233)',
  },
};

export const topBannerContentStyle = {
  color: '#FFFFFF',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  verticalAlign: 'top',
  fontSize: 12,
};

export const styles = (_theme: any) => ({
  topBanner: {
    background: '#A80000',
    padding: '0px 10px 0px 8px',
    lineHeight: '36px',
    height: '37px',
    borderBottom: '1px solid #e1dfdd',
    display: 'flex',
  },
  content: topBannerContentStyle,
  errorIcon: {
    color: '#FFFFFF',
    display: 'inline-block',
    paddingLeft: 10,
    paddingRight: 8,
    fontSize: '16px !important',
    flexShrink: 0,
    lineHeight: '36px !important',
    top: '0px !important',
  },
  addButton: {
    padding: 5,
    cursor: 'pointer',
  },
  decButton: {
    padding: 5,
    cursor: 'pointer',
  },
  invalidButton: {
    cursor: 'not-allowed !important',
    color: 'rgba(255,255,255,0.4)',
    fontSize: 12,
  },
  error: {
    color: '#FFFFFF',
    fontSize: 12,
  },
  combination: {
    display: 'inline-block',
    flexShrink: 0,
  },
  actionButton: actionButtonStyle,
  closeButton: {
    position: 'absolute',
    right: 10,
  },
});

export const buttonStyle = (_theme: any) => ({
  content: {
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    verticalAlign: 'top',
    fontSize: 12,
  },
  calloutTargetContainer: {
    flexShrink: 0,
  },
  button: actionButtonStyle,
});

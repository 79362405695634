import _ from 'underscore';
import { DateUtils, NumberUtils } from '@bingads-webui-clientcenter/common-utils';
import { DocumentType as BillingDocumentType, CreditMemoDocumentType, PaymodErrorCodes, DocumentIdAttribute } from './constants/billing';
import { PaymentOptionId } from './constants/payment';

/**
 * Returns true if documentType is CurrentActivity
 * @param {string} documentType the documentType string
 * @returns {string} true if documentType is CurrentActivity, false otherwise
 */
export const isTypeCurrentActivity = documentType => documentType === BillingDocumentType.CurrentActivity;

/**
 * Returns true if documentType is PrePaidInvoices
 * @param {string} documentType the documentType string
 * @returns {string} true if documentType is PrePaidInvoices, false otherwise
 */
export const isDocumentTypePrePaidInvoices = documentType => documentType === BillingDocumentType.PrePaidInvoices;

/**
 * Returns true if documentType is PrePaidStatement
 * @param {string} documentType the documentType string
 * @returns {string} true if documentType is PrePaidStatement, false otherwise
 */
export const isDocumentTypePrePaidStatement = documentType => documentType === BillingDocumentType.PrePaidStatement;

/**
 * Returns true if documentType is PostPaidStatement
 * @param {string} documentType the documentType string
 * @returns {string} true if documentType is PostPaidStatement, false otherwise
 */
export const isDocumentTypePostPaidStatement = documentType => documentType === BillingDocumentType.PostPaidStatement;

/**
 * Returns true if documentType is credit memo
 * @param {string} documentType the documentType string
 * @returns {string} true if documentType is credit memo, false otherwise
 */
export const isDocumentTypeCreditMemo = documentType => _.contains(CreditMemoDocumentType, documentType);

/**
 * Returns true if documentType is monthly statement
 * @param {string} documentType the documentType string
 * @returns {string} true if documentType is monthly statement, false otherwise
 */
export const isDocumentTypeMonthlyStatement = documentType => documentType === BillingDocumentType.MonthlyStatement;

/**
 * Returns the billing document date range
 * @param {object} billingDocument the billingDocument object
 * @param {object} options Some optional options for date formatting: pass i18n and dateFormat
 * @returns {string} the billing document date range in the following format: startDate-endDate if they both exists, or only one of them. Is no date, returns empty string
 */
export const getDocumentDateRangeFormatted = (
  { ActivityStartDate, ActivityEndDate, DocumentType },
  { i18n, dateFormat } = {}
) => {
  const getDateFormatted = date => (date && i18n && dateFormat ? i18n.formatDate(new Date(date), { format: dateFormat }) : date);
  const startDate = getDateFormatted(ActivityStartDate);
  const endDate = getDateFormatted(ActivityEndDate);
  const isCreditMemoSameDate = isDocumentTypeCreditMemo(DocumentType) && startDate === endDate;

  return (isDocumentTypePrePaidInvoices(DocumentType) || isCreditMemoSameDate) ?
    `${startDate || ''}` :
    `${startDate || ''}${startDate && endDate ? ' - ' : ''}${endDate || ''}`;
};

/**
 * Returns true if billingDocument is consolidated, based on different backend logic
 * @param {object} billingDocument the billingDocument object
 * @returns {string} true if billingDocument is consolidated, false otherwise
 */
export const isConsolidated = billingDocument => _.isObject(billingDocument) && (billingDocument.IsStatementConsolidated === 'True' || billingDocument.IsStatementConsolidated === true || billingDocument.IsConsolidatedBilling === true);

/**
 * Returns true if billingDocument is consolidated for billing group
 * @param {object} billingDocument the billingDocument object
 * @param {string} customerId the customerId
 * @returns {string} true if billingDocument is consolidated, false otherwise
 */
export const isConsolidatedForBillingGroup = (billingDocument, customerId) => isConsolidated(billingDocument) && billingDocument.BillToCustomerId === customerId && !_.isEmpty(billingDocument.BillingGroupName);

/**
 * Returns the billing document type text, or a noValue string if not a type to display
 * @param {object} billingDocument the billingDocument object
 * @param {object} options the options for type formatting: pass i18n and optional noValueStr (by default '-')
 * @returns {string} the billing document type text, or a noValueStr if not a type to display
 */
export const getDocumentTypeText = ({ DocumentType }, { i18n, noValueStr = '-' }) => {
  if (!DocumentType || isTypeCurrentActivity(DocumentType)) {
    return noValueStr;
  }
  const i18nSuffix = DocumentType === BillingDocumentType.Pending ? 'Pending' : DocumentType;
  return i18n.getString(`Billing_ColumnValue_DocumentType_${i18nSuffix}`);
};

/**
 * Returns the past due information based on the input billing information
 * @param {number} paymentOptionId account's paymentOptionId
 * @param {number} amountBalance account's amount balance
 * @param {number} amountPaid account's paid amount
 * @param {date} dueDate due date
 * @param {boolean} isRebilled is rebilled
 * @param {object} i18n i18n
 * @returns {object} pastDueStatusText and the cssClass.
 */
export const getPastDueInfo = ({
  paymentOptionId,
  amountBalance,
  amountPaid,
  dueDate,
  isRebilled,
  i18n,
}) => {
  let pastDueStatusText;
  let cssClass;
  const rebilledText = isRebilled && parseInt(paymentOptionId, 10) === PaymentOptionId.Invoice
    ? i18n.getString('Billing_ColumnValue_Rebilled')
    : undefined;

  if (parseInt(paymentOptionId, 10) === PaymentOptionId.Invoice && parseFloat(amountBalance) === 0 && amountPaid > 0) {
    pastDueStatusText = i18n.getString('Billing_ColumnValue_Paid');
    cssClass = 'paid';
  } else if (_.isDate(dueDate) && amountBalance > 0) {
    const pstNowDate = DateUtils.getPSTDateNow({ i18n });
    const pastDueDay = DateUtils.getDateDiffInDays({ fromDate: pstNowDate, toDate: dueDate });
    if (pastDueDay > 0) {
      pastDueStatusText = i18n.getString('Billing_ColumnValue_PastDueTemplate').replace('{0}', pastDueDay);
      cssClass = 'pastdue';
    } else {
      pastDueStatusText = i18n.getString('Billing_ColumnValue_Open');
    }
  }

  return { pastDueStatusText, cssClass, rebilledText };
};


/**
 * @param {Uri} billingUrlBuilder the base billing Url Builder
 * @param {number} billingDocumentId the billing document Id to be rendered. If not valid will return null.
 * @param {number} advertiserCustomerId the advertisercustomerId of the target bd.
 * @param {number} accountId the accountId of the target bd.
 * @param {BillingDocumentRenderingMethod} methodId the method Id calling the BD rendering.
 * @param {string} documentType the type of the bd
 * @param {number} dualInvoiceType indicate whether get the dual invoice for IN
 * @param {bool} requireINTaxInvoice indicate whether require India tax invoice documemnt (with QRCode and IRN)
 * @returns {string} Gets the URL for rendering a billing document.
 */
export const getBillingDocumentUrl = ({
  billingUrlBuilder,
  billingDocumentId,
  advertiserCustomerId,
  billtoCustomerId,
  accountId,
  accountNumber,
  methodId,
  documentType,
  dualInvoiceType,
  requireINTaxInvoice,
}) => (NumberUtils.isValidWholeNumber(billingDocumentId) ? billingUrlBuilder.action('RenderBillingDocument').params({
  id: billingDocumentId,
  advertiserCustomerId,
  billtoCustomerId,
  accountId,
  accountNumber,
  systemId: 1,
  methodId,
  documentType,
  dualInvoiceType,
  requireINTaxInvoice,
}).withoutAid().absoluteUrl : null);

/**
 * Returns a copy of the given billing document object with additional utility methods.
 * @param {object} billingDocument The billingDocument object to be extended with additional utility methods.
 * @param {object} options the i18n object
 * @returns {object} A copy of the extended account object.
 */
export const extendBillingDocument = (billingDocument = {}, options = {}) => _.defaults({}, billingDocument, {
  dateRangeFormatted: () => getDocumentDateRangeFormatted(billingDocument, options),
  isConsolidated: () => isConsolidated(billingDocument),
  documentTypeText: () => getDocumentTypeText(billingDocument, options),
});

/**
 * @typedef {object} PaymodErrorAlertModel
 * @property {string} heading The heading text
 * @property {string} description The description text
 * @property {Array} actions The actions
 * @property {object} helpAction The help action
 * @property {string} helpAction.text The help action text
 * @property {string} helpAction.helpQuery The help action helpQuery
 */

/**
 * Get paymod error alert model
 * @function
 * @param {object} arg function argument
 * @param {object} arg.i18n i18n model
 * @param {object} arg.urls urls from react-router
 * @param {string} arg.paymodErrorCode paymod error code
 * @param {number} arg.accountId account id
 * @returns {PaymodErrorAlertModel} paymod error alert model
 */
export const getPaymodErrorAlertModel = ({
  i18n,
  urls,
  paymodErrorCode,
  accountId,
}) => {
  let heading = '';
  let description = '';
  const helpAction = { helpQuery: '50826', text: i18n.getString('LearnMore') };
  const actions = [];

  const paymentMethodUrl = urls.paymentMethods({ aid: accountId });
  const payNowUrl = urls.payNow({ aid: accountId });
  const prepayUrl = urls.addFunds({ aid: accountId });

  const managePaymentMethodAction = { text: i18n.getString('PaymodError_ManagePaymentMethods'), url: paymentMethodUrl };
  const changePaymentMethodsAction = { text: i18n.getString('PaymodError_ChangePaymentMethods'), url: paymentMethodUrl };
  const makeManualPaymentAction = { text: i18n.getString('PaymodError_MakeManualPayment'), url: payNowUrl };
  const makeManualPaymentPrepayAction = { text: i18n.getString('PaymodError_MakeManualPayment'), url: prepayUrl };

  switch (paymodErrorCode) {
    case PaymodErrorCodes.ProcessorDeclined:
    case PaymodErrorCodes.ProcessorRiskcheckDeclined:
    case PaymodErrorCodes.TransactionNotAllowed:
    case PaymodErrorCodes.RiskReject:
      heading = i18n.getString('PaymodError_Title_BankDecline');
      description = i18n.getString('PaymodError_Description_BankDecline');
      break;
    case PaymodErrorCodes.ExpiredPaymentInstrument:
      heading = i18n.getString('PaymodError_Title_ExpiredCard');
      description = i18n.getString('PaymodError_Description_ExpiredCard');
      actions.unshift(managePaymentMethodAction);
      break;
    case PaymodErrorCodes.AmountLimitExceeded:
      heading = i18n.getString('PaymodError_Title_ExceedsTransactionLimit_ErrorFlyout');
      description = i18n.getString('PaymodError_Description_ExceedsTransactionLimit');
      actions.unshift(managePaymentMethodAction);
      break;
    case PaymodErrorCodes.InvalidPaymentInstrument:
      heading = i18n.getString('PaymodError_Title_InvalidPaymentInstrument');
      description = i18n.getString('PaymodError_Description_InvalidPaymentInstrument');
      actions.unshift(managePaymentMethodAction);
      break;
    case PaymodErrorCodes.CVVValueMismatch:
      heading = i18n.getString('PaymodError_Title_CVVValueMismatch');
      description = i18n.getString('PaymodError_Description_CVVValueMismatch');
      actions.unshift(managePaymentMethodAction);
      break;
    case PaymodErrorCodes.InsufficientFund:
      heading = i18n.getString('PaymodError_Title_InsufficientFunds');
      description = i18n.getString('PaymodError_Description_InsufficientFunds');
      actions.unshift(changePaymentMethodsAction);
      break;
    case PaymodErrorCodes.MissingFundingSource:
      heading = i18n.getString('PaymodError_Title_PaymentDeclined');
      description = i18n.getString('PaymodError_Description_MissingFundingSource');
      actions.unshift(changePaymentMethodsAction);
      break;
    case PaymodErrorCodes.AuthRequired:
      heading = i18n.getString('PaymodError_Title_PaymentDeclined');
      description = i18n.getString('PaymodError_Description_PSD2');
      actions.unshift(makeManualPaymentAction);
      break;
    case PaymodErrorCodes.InvalidTransactionDataPrepay:
      heading = i18n.getString('PaymodError_Title_SystemIssue_ErrorFlyout');
      description = i18n.getString('PaymodError_Description_InvalidTransactionData_Prepay');
      actions.unshift(makeManualPaymentPrepayAction);
      break;
    case PaymodErrorCodes.InvalidTransactionDataThreshold:
      heading = i18n.getString('PaymodError_Title_SystemIssue_ErrorFlyout');
      description = i18n.getString('PaymodError_Description_InvalidTransactionData_Threshold');
      actions.unshift(makeManualPaymentAction);
      break;
    case PaymodErrorCodes.AuthorizationExpired:
    case PaymodErrorCodes.IncorrectPinOrPasscode:
    case PaymodErrorCodes.MissingSessionData:
    case PaymodErrorCodes.InvalidTransactionData:
    default:
      heading = i18n.getString('PaymodError_Title_SystemIssue_ErrorFlyout');
      description = i18n.getString('PaymodError_Description_SystemIssue');
      break;
  }

  return {
    heading,
    description,
    actions,
    helpAction,
  };
};

/**
 * @typedef {object} BillingSummary
 * @property {number} PaymentOptionId The paymentOptionId of the billingSummary
 */

/**
 * Is designated billing summary
 * @function
 * @param {BillingSummary} billingSummary - The billingSummary object
 * @param {number} paymentOptionId - The payment option id
 * @returns {bool} Whether the billingSummary is designated billing summary
 */
export const isDesignatedBillingSummary = (billingSummary, paymentOptionId) =>
  _.result(billingSummary, 'PaymentOptionId') === paymentOptionId;

/**
 * Is prepay billing summary
 * @function
 * @param {BillingSummary} billingSummary - The billingSummary object
 * @returns {bool} Whether the billingSummary is prepay or not
 */
export const isPrepayBillingSummary = billingSummary => isDesignatedBillingSummary(billingSummary, PaymentOptionId.Prepay);

/**
 * Is threshold billing summary
 * @function
 * @param {BillingSummary} billingSummary - The billingSummary object
 * @returns {bool} Whether the billingSummary is threshold or not
 */
export const isThresholdBillingSummary = billingSummary => isDesignatedBillingSummary(billingSummary, PaymentOptionId.Threshold);

/**
 * Is invoice billing summary
 * @function
 * @param {BillingSummary} billingSummary - The billingSummary object
 * @returns {bool} Whether the billingSummary is invoice or not
 */
export const isInvoiceBillingSummary = billingSummary => isDesignatedBillingSummary(billingSummary, PaymentOptionId.Invoice);

/**
 * Is coupon billing summary
 * @function
 * @param {BillingSummary} billingSummary - The billingSummary object
 * @returns {bool} Whether the billingSummary is coupon or not
 */
export const isCouponBillingSummary = billingSummary => !_.result(billingSummary, 'PaymentOptionId');

/**
 * The getBillingSummary factory
 * @function
 * @param {Array.<BillingSummary>} [billingSummaries=[]] - The array of BillingSummaries
 * @param {function(BillingSummary):bool} filter - The designated billingSummary filter
 * @returns {function():BillingSummary} The function to find the billingSummary satisfying the filter
 */
export const getDesignatedBillingSummaryFactory = (billingSummaries = [], filter) =>
  () => _.find(billingSummaries, filter);

/**
 * Get designated billing summary
 * @function
 * @param {Array.<BillingSummary>} billingSummaries - The array of BillingSummaries
 * @param {number} paymentOptionId - The payment option id
 * @returns {BillingSummary} The designated BillingSummary found
 */
export const getDesignatedBillingSummary = (billingSummaries, paymentOptionId) =>
  getDesignatedBillingSummaryFactory(billingSummaries, billingSummary => isDesignatedBillingSummary(billingSummary, paymentOptionId))();

/**
 * Get prepay billing summary
 * @function
 * @param {Array.<BillingSummary>} billingSummaries - The array of BillingSummaries
 * @returns {BillingSummary} The prepay BillingSummary found
 */
export const getPrepayBillingSummary = billingSummaries => getDesignatedBillingSummary(billingSummaries, PaymentOptionId.Prepay);

/**
 * Get threshold billing summary
 * @function
 * @param {Array.<BillingSummary>} billingSummaries - The array of BillingSummaries
 * @returns {BillingSummary} The threshold BillingSummary found
 */
export const getThresholdBillingSummary = billingSummaries => getDesignatedBillingSummary(billingSummaries, PaymentOptionId.Threshold);

/**
 * Get invoice billing summary
 * @function
 * @param {Array.<BillingSummary>} billingSummaries - The array of BillingSummaries
 * @returns {BillingSummary} The invoice BillingSummary found
 */
export const getInvoiceBillingSummary = billingSummaries => getDesignatedBillingSummary(billingSummaries, PaymentOptionId.Invoice);

/**
 * Get coupon billing summary
 * @function
 * @param {Array.<BillingSummary>} billingSummaries - The array of BillingSummaries
 * @returns {BillingSummary} The coupon BillingSummary found
 */
export const getCouponBillingSummary = billingSummaries =>
  getDesignatedBillingSummaryFactory(billingSummaries, isCouponBillingSummary)();

/**
 * Extend billingSummary with some useful functions
 * @function
 * @param {BillingSummary} billingSummary - The billingSummary to extend
 * @returns {BillingSummary} The extended billingSummary
 */
export const extendBillingSummary = billingSummary => _.defaults({}, billingSummary, {
  isPrepayBillingSummary: () => isPrepayBillingSummary(billingSummary),
  isThresholdBillingSummary: () => isThresholdBillingSummary(billingSummary),
  isInvoiceBillingSummary: () => isInvoiceBillingSummary(billingSummary),
  isCouponBillingSummary: () => isCouponBillingSummary(billingSummary),
});

/**
 * @typedef {object} AccountInformation
 * @property {string} accountName The account name
 * @property {string} accountNumber The account number
 * @property {string} accountLink The link to the account billing documents page
 */

/**
 * Get the account info from billing document
 * @function
 * @param {object} param param
 * @property {object} param.billingDocument The billing document data from server
 * @property {object} param.i18n The i18n object
 * @property {number} param.cid The customer Id
 * @property {object} param.urls The urls parsed by react router
 * @property {bool} param.addBasename Whether to add basename into accountLink
 * @returns {AccountInformation} The account information
 */
export const getBillingDocumentAccountInfo = ({
  billingDocument,
  i18n,
  cid,
  urls = {},
  addBasename = false,
} = {}) => {
  const isConsolidatedBilling = isConsolidated(billingDocument);
  const accountName = isConsolidatedBilling ? i18n.getString('Billing_ColumnValue_MultipleAccounts') : billingDocument.AccountName;
  const accountNumber = isConsolidatedBilling ? undefined : billingDocument.AccountNumber;
  const accountLink = _.isFunction(urls && urls.current) && urls.current({ cid, aid: billingDocument.AccountId }, { addBasename });

  return {
    accountName,
    accountNumber,
    accountLink: !isConsolidatedBilling && accountLink,
  };
};

/**
 * Format the given number according to billing rules
 * @param {number|string} amount The amount to be formatted
 * @param {object} i18n The i18n object
 * @returns {string} The formatted decimal number according to billing rules
 */
export const formatDecimal = (amount, i18n) => {
  const amountNumber = _.isNumber(amount) ? amount : i18n.parseDecimal(amount);

  return i18n.formatDecimal(amountNumber, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });
};

export const mergeDocumentWithInfo = ({
  documents,
  documentInfos,
  documentIdAttribute = DocumentIdAttribute,
  documentInfosIdAttribute = DocumentIdAttribute,
}) => _.chain(documents)
  .map((document) => {
    const documentInfo = _.findWhere(documentInfos, { [documentInfosIdAttribute]: document[documentIdAttribute] });
    return documentInfo ? _.extendOwn({}, document, documentInfo) : undefined;
  })
  .compact()
  .value();

import _ from 'underscore';

export const AdjustmentReason = {
  None: 0,
  CustomerRetention: 41,
  CreditAndRebill: 42,
  CreditAndRebillTaxIssue: 43,
  Fraud: 44,
  SystemIssues: 45,
  InternalUserError: 46,
  VolatileTrafficComplaint: 47,
  Broadmatch: 48,
  PrepayBalanceRefund: 50,
  AccountCompromised: 52,
  TravelSpecialProgram: 53,
  GAAPCompliance: 57,
};

export const AvailableCreditAndRebillReasonIds = [
  AdjustmentReason.None,
  AdjustmentReason.CustomerRetention,
];

export const AdjustmentDisabledReason = {
  None: 0,
  AdjustmentInProgress: 1,
  AggregatorAccount: 2,
  OfflinePayment: 3,
  NonPrepaidAccountOnPrepaidInvoice: 4,
  AlreadyRebilled: 5,
  EndOfMonthHoldOnAggregatorAndConsolidatedRebills: 6,
  PIDeclined: 7,
  EndOfDunningCycle: 8,
};

/**
 * Available Action ids for adjustments
 */
export const AdjustmentsAction = Object.freeze({
  None: 0,
  Debit: 1,
  Credit: 2,
  SpotDebit: 3,
  SpotCredit: 4,
  CurrentBalanceDebit: 53,
  CurrentBalanceCredit: 54,
});

export const AvailableCreditAndRebillActionIds = [
  AdjustmentsAction.None,
  AdjustmentsAction.Credit,
];

export const AdjustmentStrings = {
  SilentErrorMessage: ' ',
};

export const AdjustmentsType = {
  Debit: 1,
  Credit: 2,
  SpotDebit: 3,
  SpotCredit: 4,
  TrueUpDebit: 5,
  Payment: 6,
  Billing: 7,
  Invoice: 8,
  CreditMemo: 9,
  ChargeBack: 10,
  DebitMemo: 11,
  BudgetCredit: 12,
  BudgetDebit: 13,
  TrueUpCredit: 14,
  Charge: 15,
  SetupFee: 16,
  WriteOff: 17,
  DealpointCredit: 18,
  ManualDebit: 19,
  ManualCredit: 20,
  PublisherFraudReinvestmentDebit: 21,
  PublisherFraudReinvestmentCredit: 22,
  PayoutPaymentIssued: 23,
  PayoutEarnings: 24,
  PayoutLateLogs: 25,
  BatchPayoutManualDebit: 26,
  BatchPayoutManualCredit: 27,
  PayoutPaymentDeclined: 28,
  PayoutTrueUpDebit: 29,
  PayoutTrueUpCredit: 30,
  StandardPayoutManualDebit: 31,
  StandardPayoutManualCredit: 32,
  YahooAdjustmentCredit: 33,
  YahooAdjustmentDebit: 34,
  ContractualPayoutDebit: 35,
  ContractualPayoutCredit: 36,
  ThirdPartyPipelineDebit: 37,
  ThirdPartyPipelineCredit: 38,
  YahooAgencyDiscountCredit: 39,
  YahooAgencyDiscountDebit: 40,
  CrossOverDebit: 41,
  CrossOverCredit: 42,
  PrePaidStatement: 43,
  PrePaidPayments: 44,
  PrepaidCreditMemo: 45,
  PrePaidCredit: 47,
  PaymentFraudDebit: 48,
  PaymentFraudCredit: 49,
  PrepaidPaymentReversal: 50,
  PrepaidRefundReversal: 51,
  PaymentFraudDeclineCredit: 52,
  CurrentBalanceDebit: 53,
  CurrentBalanceCredit: 54,
  PostPaidStatement: 55,
  ExternalCharge: 56,
  PublisherFraudDebit: 57,
  PublisherFraudCredit: 58,
  PublisherFraudPayoutDebit: 59,
  PublisherFraudPayoutCredit: 60,
  RollForwardDirectCreditRefund: 61,
  RollForwardDirectDebitRefund: 62,
};

export const CreditActions = [
  AdjustmentsType.Credit,
  AdjustmentsType.SpotCredit,
  AdjustmentsType.Payment,
  AdjustmentsType.CreditMemo,
  AdjustmentsType.BudgetCredit,
  AdjustmentsType.TrueUpCredit,
  AdjustmentsType.WriteOff,
  AdjustmentsType.DealpointCredit,
  AdjustmentsType.ManualCredit,
  AdjustmentsType.PublisherFraudReinvestmentCredit,
  AdjustmentsType.BatchPayoutManualDebit,
  AdjustmentsType.PayoutTrueUpDebit,
  AdjustmentsType.StandardPayoutManualDebit,
  AdjustmentsType.YahooAdjustmentCredit,
  AdjustmentsType.ContractualPayoutDebit,
  AdjustmentsType.ThirdPartyPipelineDebit,
  AdjustmentsType.YahooAgencyDiscountCredit,
  AdjustmentsType.CrossOverDebit,
  AdjustmentsType.PrepaidCreditMemo,
  AdjustmentsType.PrePaidCredit,
  AdjustmentsType.PaymentFraudCredit,
  AdjustmentsType.PrepaidPaymentReversal,
  AdjustmentsType.PaymentFraudDeclineCredit,
  AdjustmentsType.CurrentBalanceCredit,
  AdjustmentsType.PublisherFraudCredit,
  AdjustmentsType.PublisherFraudPayoutDebit,
  AdjustmentsType.RollForwardDirectCreditRefund,
];

export const DebitActions = _.difference(_.values(AdjustmentsType), CreditActions);

export const AdjustmentsStatus = {
  Created: 0,
  Queued: 1,
  InProgress: 2,
  Completed: 3,
  Failed: 4,
};

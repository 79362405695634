import React from 'react';
import classNames from 'classnames';
import { Glyphicon, Button } from '@bingads-webui-react/primitive';
// @ts-ignore
import { withI18nConsumer } from '@bingads-webui-react/i18n-context';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { submitEvent } from '@bingads-webui-universal/accessibility-helper';
import { withStateStore } from '@bingads-webui/with-state-store';
import { styles } from './styles';

import { notificationStore } from '../notification-store';
import { INotification, TopBannerNotification } from '../types';
import { ButtonWrapper as DefaultButtonWrapper } from './button-wrapper';

interface ITopBannerProps {
  notifications: TopBannerNotification[];
  i18n: any;
  classes: any;
  Wrapper: React.ComponentClass<any>; // this is for warpping part of the message as button or callout
}

interface ITopBannerState {
  curIndex: number;
}

export class UnstyledTopBanner extends React.PureComponent<ITopBannerProps, ITopBannerState> {
  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    Wrapper: DefaultButtonWrapper,
  };

  constructor(props: ITopBannerProps) {
    super(props);
    this.state = {
      curIndex: 0,
    };
  }

  static getDerivedStateFromProps(nextProps: ITopBannerProps, prevState: ITopBannerState) {
    if (prevState.curIndex >= nextProps.notifications.length) {
      return {
        curIndex: 0,
      };
    }
    return null;
  }

  // eslint-disable-next-line react/sort-comp
  render() {
    const {
      notifications, i18n, classes, Wrapper,
    } = this.props;
    if (notifications.length <= 0) {
      return <span />;
    }

    const curMsg = notifications[this.state.curIndex].message;
    const curNotificationId = notifications[this.state.curIndex].notificationId;

    const indexMsg = i18n.getString(
      _TL_('{{page}} of {{total}}'),
      { page: this.state.curIndex + 1, total: notifications.length },
    );

    const canDecrease = this.state.curIndex > 0;
    const decClassNames = `${classes.decButton} ${canDecrease ? classes.error : classes.invalidButton}`;

    const canIncrease = this.state.curIndex + 1 < notifications.length;
    const addClassNames = `${classes.addButton} ${canIncrease ? classes.error : classes.invalidButton}`;

    return (
      <aside
        className={classNames('notification-center-top-banner', classes.topBanner)}
        aria-label={i18n.getString(_TL_('Banner message'))}
      >
        {notifications.length > 1 ? (
          <div className={classes.combination}>
            <Glyphicon
              className={classNames('notification-center-top-banner-dec-button', decClassNames)}
              glyph="ba-ChevronLeftMed"
              onClick={this.decIndex}
              role="button"
              tabIndex={0}
              aria-label={this.props.i18n.getString(_TL_('Previous message'))}
              aria-disabled={!canDecrease}
              onKeyDown={submitEvent(this.decIndex)}
            />
            <span className={classNames('notification-center-top-banner-index', classes.error)}>{indexMsg}</span>
            <Glyphicon
              className={classNames('notification-center-top-banner-add-button', addClassNames)}
              glyph="ba-ChevronRightMed"
              onClick={this.addIndex}
              role="button"
              tabIndex={0}
              aria-label={i18n.getString(_TL_('Next message'))}
              aria-disabled={!canIncrease}
              onKeyDown={submitEvent(this.addIndex)}
            />
          </div>
        ) : null}

        <Glyphicon className={classes.errorIcon} glyph="ba-CriticalErrorSolid" />
        <Wrapper
          message={curMsg}
          notificationId={curNotificationId}
          i18n={i18n}
        />
        {notifications[this.state.curIndex].actionList?.map((action, index) => (
          action.text
          && (
          <Button
            className={classNames(classes.actionButton, 'notification-center-top-banner-action', action.extraClassNames)}
            onClick={() => this.actionClickHandler(action.action)}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...action.extraAttrs}
          >
            {action.text}
          </Button>
          )
        ))}
        {notifications[this.state.curIndex].onDismiss && (
          <div
            role="button"
            className={classNames(classes.closeButton, 'notification-center-top-banner-close-button')}
            onClick={() => notifications[this.state.curIndex].onDismiss(curNotificationId)}
            aria-label={i18n.getString(_TL_('Dismiss'))}
            tabIndex={0}
            onKeyDown={submitEvent(() => notifications[this.state.curIndex].onDismiss(curNotificationId))}
          >
            <Glyphicon glyph="ba-Cancel" />
          </div>
        )}
      </aside>
    );
  }

  addIndex = () => {
    if (this.state.curIndex + 1 >= this.props.notifications.length) {
      return;
    }

    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      curIndex: this.state.curIndex + 1,
    });
  };

  decIndex = () => {
    if (this.state.curIndex <= 0) {
      return;
    }

    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      curIndex: this.state.curIndex - 1,
    });
  };

  actionClickHandler = (action?: Function) => {
    if (action) {
      action();
    }
  };
}

export const TopBanner = withI18nConsumer(withDefaultStyles(UnstyledTopBanner, styles));
export const TopBannerWithStateStore = withStateStore(
  TopBanner,
  notificationStore,
  (store: any) => {
    const notifications = store.state.bannerNotificationList.map((notification: INotification) => ({
      message: notification.message,
      actionList: notification.actionList,
    }));
    return {
      notifications,
    };
  },
) as any;

import { NotificationLevel, ExtraOptions } from '../types';
import { IOldAlert, IOldAlertCenterApi } from './api.types';
import { notificationStore } from '../notification-store';

export class OldAlertCenterAdapter implements IOldAlertCenterApi {
  show(alert: IOldAlert, extraOptions?: ExtraOptions): string {
    // Transform level,
    // - Info in old Alert is Info
    // - Info in UNQ is Information.........
    let level: NotificationLevel.Info | NotificationLevel.Warning;
    if (
      !alert.level
      || alert.level === 'Info'
      || alert.level === NotificationLevel.Confirmation
      || alert.level === NotificationLevel.Info
    ) {
      level = NotificationLevel.Info;
    } else {
      level = NotificationLevel.Warning;
    }

    return notificationStore.setFloatingBannerNotification(alert.message, level, extraOptions);
  }

  dismiss(id: string) {
    notificationStore.dismiss(id);
  }

  dismissAll() {
    throw new Error('Not implemented');
  }

  toastError(message: string, extraOptions?: ExtraOptions) {
    return this.toastWarning(message, extraOptions);
  }

  toastWarning(message: string, extraOptions?: ExtraOptions) {
    return notificationStore.setFloatingBannerNotification(message, NotificationLevel.Warning, extraOptions);
  }

  toastInfo(message: string, extraOptions?: ExtraOptions) {
    return notificationStore.setFloatingBannerNotification(message, NotificationLevel.Info, extraOptions);
  }

  toastConfirmation(message: string, extraOptions?: ExtraOptions) {
    return this.showInfo(message, extraOptions);
  }

  showError(message: string, extraOptions?: ExtraOptions) {
    return this.toastWarning(message, extraOptions);
  }

  showWarning(message: string, extraOptions?: ExtraOptions) {
    return this.toastWarning(message, extraOptions);
  }

  showInfo(message: string, extraOptions?: ExtraOptions) {
    return this.toastInfo(message, extraOptions);
  }

  showConfirmation(message: string, extraOptions?: ExtraOptions) {
    return this.toastConfirmation(message, extraOptions);
  }
}

// (window as any).App.views.alertCenter =
export const oldAlertCenterAdapter = new OldAlertCenterAdapter();

// Put it here only for debugging purpose
(window as any).oldAlertCenterAdapter = oldAlertCenterAdapter;
(window as any).notificationStore = notificationStore;

import _ from 'underscore';
import URI from 'urijs';

export function IsFraudAppealPage({ urls, pageSuffix = '' }) {
  const fraudPageId = `fraudAppeal${pageSuffix}`;
  const fraudPage = _.isFunction(urls && urls[fraudPageId]) && urls[fraudPageId]();
  if (_.isFunction(urls && urls.current) && fraudPage) {
    const currentUrl = URI(urls.current());
    const targetUrl = URI(fraudPage);
    return currentUrl.path() === targetUrl.path();
  }
  return false;
}

export function IsFraudAppealAIVPage({ urls }) {
  return IsFraudAppealPage({ urls, pageSuffix: 'AIV' });
}

import _ from 'underscore';
import { NumberUtils } from '@bingads-webui-clientcenter/common-utils';
import {
  AccountFinancialStatus,
  AccountLifecycleStatus,
  AccountInactiveReasonCode,
  AccountInactiveReasonValue,
  AccountActivityStatus,
  AccountFinancialStatusBit,
  AccountMode,
  AccountCustomerRelationLifecycleStatus,
} from './constants/account';
import { ManagedByType } from './constants/customer';
import { PaymentOption } from './constants/payment';
import { CountryCode } from './constants/country';
import { HierarchyAccountTypeValue } from './constants/linking';
import { TradeScreeningStatus } from './constants/trade-screening-transformation';
import { getAccountCountryCode } from './country-utils';
import { isStringNullOrWhiteSpace } from './common';

/**
 * Returns the display string of an account in the format AccountName (AccountNumber)
 * @param {object} account the account object
 * @param {*} i18n the i18n object
 * @param {*} newI18n the use new i18n object
 * @returns {string} the display string of an account
 */
export const getDisplayName = (account, i18n, newI18n = null) => {
  if (!_.isObject(account)) {
    return '';
  }
  const name = account.Name || '';
  if (newI18n) {
    return account.Number ? newI18n.getString(_TL_('{{name}} ({{number}})'), { name, number: account.Number }) : name;
  }
  return account.Number ? i18n.getString('Account_DisplayName', { name, number: account.Number }) : name;
};

/**
 * Returns the hierarchy format of an account
 * @param {object} account the account object
 * @returns {string} the hierarchy format of an account
 */
export const getHierarchyAccount = (account = {}) => ({
  id: account.id,
  name: account.Name,
  number: account.Number,
  type: HierarchyAccountTypeValue.AdAccount,
  managers: _.map(account.Managers, (manager = {}) => ({
    name: manager.Name,
    number: manager.Number,
    id: manager.Id,
  })),
});

/**
 * Returns true if the target customer is bill-to of the account in parameter, false otherwise.
 * @param {object} account the account object.
 * @param {number} targetCustomerId the target customer id to decide if the account in param is bill-to of this cid.
 * @returns {boolean} true if the target customer is bill-to of the account in parameter, false otherwise
 */
export const isBillToCustomer = (account, targetCustomerId) => parseInt(targetCustomerId, 10) >= 0 && _.isObject(account) && account.BillToCustomerId === targetCustomerId;

/**
 * Returns true if the target account is bill to agency customer, false otherwise.
 * @param {object} account the account object.
 * @returns {boolean} true if the target account is bill to agency customer, false otherwise.
 */
export const isBillToAgencyCustomer = account => _.isObject(account) && _.isNumber(account.BillToCustomerId) &&
  _.isNumber(account.ParentCustomerId) && account.ParentCustomerId !== account.BillToCustomerId;

/**
 * Returns a predicate function that will tell you if a passed in object contains the Reason value "Deleted"
 * @returns {func} a predicate function that will tell you if a passed in object contains the Reason value "Deleted"
 */
export const deletedReasonValueMatcher = _.matcher({ Reason: AccountInactiveReasonValue.Deleted });

/**
 * Returns a predicate function that will tell you if a passed in object contains the Reason Code of Deleted
 * @returns {func} a predicate function that will tell you if a passed in object contains the Reason Code of Deleted
 */
export const deletedReasonCodeMatcher = _.matcher({ Reason: AccountInactiveReasonCode.Deleted });

/**
 * Returns whether the account is deleted.
 * @param {object} account The account object.
 * @returns {bool} Return whether the account is deleted
 */
export const isDeleted = (account = {}) => account.LifecycleStatus === AccountLifecycleStatus.Inactive ||
  account.UILifecycleStatusLocId === AccountFinancialStatus.Inactive ||
  _.some(account.AccountInactiveReasonsV1, deletedReasonCodeMatcher) ||
  _.some(account.AccountInactiveReasons, deletedReasonValueMatcher); // In Campaign account object use the string values instead of the integer codes

/**
 * Returns whether the account is created.
 * @param {object} account The account object.
 * @returns {bool} Return whether the account is created
 */
export const isCreated = (account = {}) => account && account.LifecycleStatus !== AccountLifecycleStatus.Draft;

/**
 * Returns an AccountActivityStatus indicating if the account is enabled or not.
 * In case the account is not enabled, it returns a specific status (like AccountActivityStatus.Deleted)
 * In case the account doesn't have a specific AccountActivityStatus, but still
 * contains unhandled AccountInactiveReasons, AccountActivityStatus.Errors is returned
 * @param {object} account The account object.
 * @returns {string} Return an AccountActivityStatus value indicating if the account is enabled, deleted, or has any others errors preventing it to be fully enabled
 */
export const activityStatus = (account = {}) => {
  if (isDeleted(account)) {
    return AccountActivityStatus.Deleted;
  }

  const handledAccountInactiveReasons = [
    AccountInactiveReasonCode.NotSet,
    AccountInactiveReasonCode.Deleted,
    AccountInactiveReasonValue.NotSet,
    AccountInactiveReasonValue.Deleted,
  ];
  const remainingAccountInactiveReasons = _.reject(
    _.union(account.AccountInactiveReasonsV1, account.AccountInactiveReasons),
    item => _.contains(handledAccountInactiveReasons, item.Reason)
  );

  return _.isEmpty(remainingAccountInactiveReasons) ? AccountActivityStatus.Enabled : AccountActivityStatus.Errors;
};

/**
 * Returns true if the account is manual hold, false otherwise.
 * @param {object} account The account object.
 * @returns {boolean} true if the account is manual hold, false otherwise.
 */
export const isManualHold = (account = {}) => NumberUtils.hasFlag(account.LastFinStatusUpdatedSourceBitMap, AccountFinancialStatusBit.ManualHold);

/**
 * Returns true if the account is smart account, false otherwise.
 * @param {object} account The account object.
 * @returns {boolean} true if the account is smart account, false otherwise.
 */
export const isSmartAccount = account => account.AccountMode === AccountMode.Smart;

/**
 * Returns true if the account is unified smart account, false otherwise.
 * @param {object} account The account object.
 * @returns {boolean} true if the account is unified smart account, false otherwise.
 */
export const isUnifiedSmartAccount = account => account.AccountMode === AccountMode.UnifiedSmart;

/**
 * Returns true if the account financial status is write off, false otherwise.
 * @param {object} account the account object.
 * @returns {boolean} true if the account financial status is write off, false otherwise.
 */
export const isWriteOff = account => account.FinancialStatus === AccountFinancialStatus.WriteOff;

/**
 * Returns true if the given account object has known payment option set.
 * @param {object} account The account object.
 * @returns {bool} true if the given account object has known payment option set.
 */
export const hasPaymentOption = account => _.isObject(account) && _.contains(_.values(PaymentOption), account.PaymentOption);

/**
 * Returns true if the given account object has one of the payment options passed in paramaters
 * @param {object} account The account object
 * @param {int[]} paymentOptions The list of payment options to verify.
 * @returns {bool} true if the given account object has one of the payment option set passed in paramaters
 */
export const hasOneOfPaymentOptions = (account, paymentOptions) => hasPaymentOption(account) && _.contains(paymentOptions, account.PaymentOption);

/**
 * @typedef {object} AccountManager
 * @property {number} Id Account manager id
 * @property {string} Name Account manager name
 * @property {string} Number Account manager number
 */

/**
 * Returns the given account's managers
 * @param {object} account The account object
 * @returns {Array.<AccountManager>} Account managers
 */
export const managers = (account = {}) => _.chain(account.AccountCustomerRelations)
  .filter(({ isManagingCustomer, LifecycleStatus }) => isManagingCustomer && LifecycleStatus === AccountCustomerRelationLifecycleStatus.Active)
  .map(({ RelatedToCustomerId, RelatedToCustomerName, RelatedToCustomerNumber }) => ({
    Id: RelatedToCustomerId,
    Name: RelatedToCustomerName,
    Number: RelatedToCustomerNumber ? RelatedToCustomerNumber.trim() : null,
  }))
  .value();

/**
 * Returns true if the given account has manager
 * @param {object} account The account object
 * @returns {bool} true if the given account has manager
 */
export const hasManager = (account = {}) => managers(account).length > 0;

/**
 * Returns true if the given account object is mandatory for account level ap contact
 * @param {object} account The account object
 * @returns {bool} true if the given account object is mandatory for account level ap contact
 */
export const isAccountAPContactMandatory = (account) => {
  if (!_.isObject(account) || !_.isObject(account.BusinessAddress)) {
    return false;
  }
  const invoiceMandatoryCountries = [CountryCode.US, CountryCode.FR];
  const prepayThresholdMandatoryCountries = [CountryCode.FR];

  const isInvoiceMandatory = hasOneOfPaymentOptions(account, [PaymentOption.Invoice]) && isBillToAgencyCustomer(account) &&
    _.contains(invoiceMandatoryCountries, account.BusinessAddress.Country);

  const isPrepayThresholdMandatory = hasOneOfPaymentOptions(account, [PaymentOption.Prepay, PaymentOption.Threshold]) &&
    _.contains(prepayThresholdMandatoryCountries, account.BusinessAddress.Country);

  return isInvoiceMandatory || isPrepayThresholdMandatory;
};

/**
 * Returns true if the given account object has india emandate failed PI
 * @param {object} account The account object
 * @param {object} currentPI The PI object
 * @returns {bool} true if the given account object has india emandate failed PI
 */
export const isIndiaEmandateFailedPIAccount = ({ account, currentPI }) => _.isObject(currentPI) &&
  getAccountCountryCode(account) === CountryCode.IN && currentPI.SupportsRecurringPayment && new Date(currentPI.CreationDate) >= new Date(2021, 3, 1, 0, 0, 0);

/**
 * Returns true if the account's parent customer is managed by Yahoo
 * @function
 * @param {object} account the account object
 * @returns {bool} true if the account's parent customer is managed by Yahoo
 */
export const isManagedByYahoo = account => _.get(account, 'ManagedBy') === ManagedByType.Yahoo;

/**
 * transform account object into ablInfo object, made for loading the shared ABL with initial data
 * @param {object} account The account object.
 * @returns {object} ablInfo object that can be consumed directly by the ABL component
 */
export const getAblInfoFormat = ({ account }) => {
  if (!account) {
    return {};
  }
  return {
    countryCode: _.get(account.BusinessAddress, 'Country'),
    addressData: { address: account.BusinessAddress },
    businessNameData: { businessName: _.get(account.BusinessAddress, 'BusinessName') },
    taxData: { data: account.TaxInformation, taxCertification: account.TaxCertification },
    prefillAddressId: _.get(account.BusinessAddress, 'Id'),
  };
};

/**
 * Check if account is in TST hit-in-review status
 * @param {object} account The account object to be extended with additional utility methods.
 * @returns {boolean} Result of whether account is in TST hit-in-review status
 */
export const isTSTInReview = account => _.get(account, 'TradeScreeningStatus') === TradeScreeningStatus.HitInReview;

/**
 * Check if account is in TST LicR status
 * @param {object} account The account object to be extended with additional utility methods.
 * @returns {boolean} Result of whether account is in TST licR status
 */
export const isTSTLicR = account => _.get(account, 'TradeScreeningStatus') === TradeScreeningStatus.LicR;

/**
 * Check if account is in TST true-match status
 * @param {object} account The account object to be extended with additional utility methods.
 * @returns {boolean} Result of whether account is in TST true-match status
 */
export const isTSTTrueMatch = account => _.get(account, 'TradeScreeningStatus') === TradeScreeningStatus.TrueMatch;

/**
 * Check if account is in TST data issue missing name status
 * @param {object} account The account object to be extended with additional utility methods.
 * @returns {boolean} Result of whether account is in TST data issue missing name status
 */
export const isTSTDataIssueName = account => (
  _.get(account, 'TradeScreeningStatus') === TradeScreeningStatus.DataIssueName ||
  _.get(account, 'TradeScreeningStatus') === TradeScreeningStatus.DataIssueOrg
);

/**
 * Check if account is in TST data issue missing address status
 * @param {object} account The account object to be extended with additional utility methods.
 * @returns {boolean} Result of whether account is in TST data issue missing address status
 */
export const isTSTDataIssueAddress = account => _.get(account, 'TradeScreeningStatus') === TradeScreeningStatus.DataIssueAddress;

/**
 * Check if account is in TST data issue missing legal identifier status
 * @param {object} account The account object to be extended with additional utility methods.
 * @returns {boolean} Result of whether account is in TST data issue missing legal identifier status
 */
export const isTSTDataIssueLegalId = account => _.get(account, 'TradeScreeningStatus') === TradeScreeningStatus.DataIssueLegalId;

/**
 * Check if account is missing master data
 * @param {object} account The account object to be extended with additional utility methods.
 * @returns {boolean} Result of whether account is missing data
 */
export const isTSTMissingMasterData = account => (
  isStringNullOrWhiteSpace(_.get(account, ['BusinessAddress', 'Country']))
  || isStringNullOrWhiteSpace(_.get(account, ['BusinessAddress', 'City']))
  || isStringNullOrWhiteSpace(_.get(account, ['BusinessAddress', 'Line1']))
  || isStringNullOrWhiteSpace(_.get(account, ['BusinessAddress', 'BusinessName'])));

/**
 * Returns a copy of the given account object with additional utility methods.
 * @param {object} account The account object to be extended with additional utility methods.
 * @param {*} i18n the i18n object
 * @returns {object} A copy of the extended account object.
 */
export const extendAccount = (account = {}, i18n) => _.defaults({}, account, {
  activityStatus: () => activityStatus(account),
  displayName: () => getDisplayName(account, i18n),
  isAccountAPContactMandatory: () => isAccountAPContactMandatory(account),
  isBillToCustomer: targetCustomerId => isBillToCustomer(account, targetCustomerId),
  isBillToAgencyCustomer: () => isBillToAgencyCustomer(account),
  isDeleted: () => isDeleted(account),
  isCreated: () => isCreated(account),
  isManualHold: () => isManualHold(account),
  isWriteOff: () => isWriteOff(account),
  hasManager: () => hasManager(account),
  hasOneOfPaymentOptions: paymentOptions => hasOneOfPaymentOptions(account, paymentOptions),
  hasPaymentOption: () => hasPaymentOption(account),
  hierarchyAccount: () => getHierarchyAccount(account),
  managers: () => managers(account),
  toAblInfoFormat: () => getAblInfoFormat({ account }),
  isIndiaEmandateFailedPIAccount: currentPI => isIndiaEmandateFailedPIAccount({ account, currentPI }),
  isManagedByYahoo: () => isManagedByYahoo(account),
  isTSTDataIssueName: () => isTSTDataIssueName(account),
  isTSTDataIssueLegalId: () => isTSTDataIssueLegalId(account),
  isTSTDataIssueAddress: () => isTSTDataIssueAddress(account),
  isTSTInReview: () => isTSTInReview(account),
  isTSTLicR: () => isTSTLicR(account),
  isTSTTrueMatch: () => isTSTTrueMatch(account),
  isTSTMissingMasterData: () => isTSTMissingMasterData(account),
});

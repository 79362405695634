/** ************************ Core Interface Start ****************************** */

import { NotificationStore } from '../notification-store';

interface INotificationAction {
  text: string;
  isJumpOut?: boolean; // only show it when it is true
  callbackType?: string;
  extraClassNames?: string[];
  extraAttrs?: {
    query: string;
  };
  action?: Function;
  href?: string;
}
export interface INotification {
  severity: NotificationLevel;
  id: string;
  title?: string;
  message: string;
  condition: boolean;
  target: TargetComponent;
  dismissMode?: DismissMode;
  dismissAction?: (notificaiton: INotification) => void;
  actionList?: INotificationAction[];
  note?: string; // Only used for test purpose

  /* scopeLevel defines when to update notification. if scopeLevel changes, then need to re-fetch notification.
   * For example, if scopeLevel is adgroup, then if campaign changes, need to update.
   *
   * availableScopes defines supported scopes of this notification.
   * For example, if scopeLevel exists and doesn't contain customer scope, then in page such as accounts, notification is not available.
   */
  scopeLevel?: ScopeLevel; // Used to track when to clear this notification
  availableScopes?: ScopeLevel[]; // Used to check if notification is available in current scope
  extraOptions?: ExtraOptions; // Used for extra options of the notification
}

export enum TargetComponent {
  TopBanner = 'TopBanner',
  UnifiedQueue = 'UnifiedQueue',
  FloatingBanner = 'FloatingBanner',
}

export enum NotificationLevel {
  Error = 'Error',
  Info = 'Information',
  Warning = 'Warning',
  Critical = 'Critical',
  Confirmation = 'Confirmation',
}

export enum DismissMode {
  permanent = 'permanent',
  session = 'session',
  never = 'never',
  once = 'once',
  custom = 'custom',
}

export interface IDismisstore {
  dismissNotification: (notificationId: string) => PromiseLike<boolean>;
  shouldShowNotification: (notificationId: string) => PromiseLike<boolean>;
}

export interface INotificationDataSource {
  getNotifications: () => PromiseLike<INotification[]>;
  shouldClearOldNotification: (notification: INotification) => boolean;
  shouldShowNewNotification: (notification: INotification) => boolean;
}

export interface IScope {
  values: {
    aid: string | number;
    cid: string | number;
    uid: string | number | null;
    adGroupId: string | number | null;
    campaignId: string | number | null;
  };
}

export enum ScopeLevel {
  root = 16,
  customer = 8,
  account = 4,
  campaign = 2,
  adGroup = 1,
}

export interface ExtraOptions {
  showActionFallbackCallout?: boolean;
  autoDismissTime?: number; // milliseconds timeout value for auto dismiss of floating banner, undefined/0 for non-auto dismiss
  onDismiss?: (type?: string, store?: NotificationStore) => void // callback function when notification is dismissed
  sessionModeDismissForFloatingBannerProps?: {
    uniqueFloatingBannerId: string; // unique floating banner id, required for floating banner session mode dismissal
  },
  dialogProps?: {
    dialogTitle?: string,
    dialogText?: string,
    dialogAction?: Function,
    dialogConfirm?: string,
    dialogCancel?: string,
  };
}

export interface TopBannerNotification {
  message: string;
  notificationId: string;
  actionList?: INotificationAction[];
  onDismiss?: (notificationId: string) => void;
}
